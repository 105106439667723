export default function Card() {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          border: "1px solid",
          borderColor: "#AFBAC3",
          boxShadow: "none",
          borderRadius: 0,
        },
      },
    },
  };
}
