import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

export type ColorSchema =
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "warning"
  | "error";

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

const PRIMARY = {
  lighter: "#D8DFE5",
  light: "#4f7696",
  main: "#3d5c75",
  dark: "#254259",
  darker: "#233543",
  contrastText: "#fff",
};

const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "#1939B7",
  darker: "#091A7A",
  contrastText: "#fff",
};

const INFO = {
  lighter: "#CAFDF5",
  light: "#61F3F3",
  main: "#00B8D9",
  dark: "#006C9C",
  darker: "#003768",
  contrastText: "#fff",
};

const SUCCESS = {
  lighter: "#D8FBDE",
  light: "#86E8AB",
  main: "#36B37E",
  dark: "#1B806A",
  darker: "#0A5554",
  contrastText: "#fff",
};

const WARNING = {
  lighter: "#FFF5CC",
  light: "#FFD666",
  main: "#FFAB00",
  dark: "#B76E00",
  darker: "#7A4100",
  contrastText: PRIMARY.darker,
};

const ERROR = {
  lighter: "#FFE9D5",
  light: "#FFAC82",
  main: "#FF5630",
  dark: "#B71D18",
  darker: "#7A0916",
  contrastText: "#fff",
};

const COMMON = {
  common: { black: "#000", white: "#fff" },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  divider: "#b1bbc4",
  action: {
    hover: alpha(PRIMARY.light, 0.08),
    selected: alpha(PRIMARY.light, 0.16),
    disabled: alpha(PRIMARY.light, 0.8),
    disabledBackground: alpha(PRIMARY.light, 0.24),
    focus: alpha(PRIMARY.light, 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default function palette() {
  const light = {
    ...COMMON,
    mode: "light",
    text: {
      primary: PRIMARY.darker,
      secondary: PRIMARY.darker,
      disabled: PRIMARY.darker,
    },
    background: {
      paper: "white",
      default: PRIMARY.lighter,
      neutral: PRIMARY.lighter,
    },
    action: {
      ...COMMON.action,
      active: PRIMARY.darker,
    },
  } as const;

  return light;
}
