import { Theme } from "@mui/material/styles";

import Button from "./Button";
import Card from "./Card";
import Chip from "./Chip";
import Container from "./Container";
import Dialog from "./Dialog";
import Input from "./Input";
import Switch from "./Switch";

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Button(theme),
    Input(theme),
    Card(),
    Chip(theme),
    Switch(theme),
    Container(),
    Dialog(theme)
  );
}
