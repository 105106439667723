export default function Container() {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "1300px",
        },
      },
    },
  };
}
