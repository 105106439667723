export const IP_ADDRESS = "localhost";

// dashboard endpoint

export const DASHBOARD_ROUTE = "/Customer/api/Dashboard";

// Notification routes
export const NOTIFICATION_ROUTE = "/notification/api/notification";

// auth routes
export const LOGIN_ROUTE = "api/Account/Login";
export const GROUP_CAPACITY_HUB = "/GroupCapacityHub";

export const BASE_URL_GROUP_CAPACITY_HUB = window.__RUNTIME_CONFIG__
  ? window.__RUNTIME_CONFIG__.REACT_APP_NOTIFICATION_SERVICE_URL
  : process.env.REACT_APP_NOTIFICATION_SERVICE_URL;

export const CHANGE_PASSWORD_ROUTE = "Customer/api/Customer/changePassword";

// group capacity routes
export const GROUP_CAPACITY_ROUTE = "groupCapacity/api/groupCapacity";
export const UPLOAD_GROUP_CAPACITY_ROUTE = `GroupCapacity/api/GroupCapacity`;
export const GROUP_CAPACITY_PURGED_ROUTE = "groupCapacity/api/groupCapacity/Purge";
// user routes
export const GET_USER_LIST_ROUTE = "Customer/api/Customer";

export const GET_USER_SEARCH_ROUTE = "Customer/api/Customer";

export const GET_USER_FUNCTIONALITIES_ROUTE = "Customer/api/CustomerProduct";

export const GET_USER_ROLE_ROUTE = "Customer/api/CustomerRole";

export const GET_GROUPS_USER_DETAILS = "Customer/api/Customer/authservice";

export const SYNC_USER = "Customer/api/Customer/usersync";

export const RESET_PASSWORD = "Customer/api/Customer/resetpassword";

export const UPDATE_AUTH_USER = "Customer/api/Customer/updateauthuser";

export const CHANGE_PASSWORD = "Customer/api/Customer/changepassword";

export const LOGIN_ROLE_OPTIONS = "Customer/api/Customer/loginoption";

// airline routes

export const GET_AIRLINE_ROUTE = "ReferenceData/api/Airline";

export const GET_AIRLINE_FUNCTIONALITY_ROUTE =
  "ReferenceData/api/AirlineProduct";

export const REFERENCE_DATA_URL = "/ReferenceData/api/ReferenceData/ClearCache";

// QF AUTH service Login
export const QF_AUTH_SERVICE_LOGIN = window.__RUNTIME_CONFIG__
  ? window.__RUNTIME_CONFIG__.REACT_APP_AUTH_SERVICE_GRPQAU_LOGIN_URL
  : process.env.REACT_APP_AUTH_SERVICE_GRPQAU_LOGIN_URL || "";
// QF AUTH SERVICE Logout
export const QF_AUTH_SERVICE_LOGOUT = window.__RUNTIME_CONFIG__
  ? window.__RUNTIME_CONFIG__.REACT_APP_AUTH_SERVICE_GRPQAU_LOGOUT_URL
  : process.env.REACT_APP_AUTH_SERVICE_GRPQAU_LOGOUT_URL || "";

// AA AUTH service Login
export const AA_AUTH_SERVICE_LOGIN = window.__RUNTIME_CONFIG__
  ? window.__RUNTIME_CONFIG__.REACT_APP_AUTH_SERVICE_GRPAAA_LOGIN_URL
  : process.env.REACT_APP_AUTH_SERVICE_GRPAAA_LOGIN_URL || "";
// AA AUTH SERVICE Logout
export const AA_AUTH_SERVICE_LOGOUT = window.__RUNTIME_CONFIG__
  ? window.__RUNTIME_CONFIG__.REACT_APP_AUTH_SERVICE_GRPAAA_LOGOUT_URL
  : process.env.REACT_APP_AUTH_SERVICE_GRPAAA_LOGOUT_URL || "";

// AY AUTH service Login
export const AY_AUTH_SERVICE_LOGIN = window.__RUNTIME_CONFIG__
  ? window.__RUNTIME_CONFIG__.REACT_APP_AUTH_SERVICE_GRPAAY_LOGIN_URL
  : process.env.REACT_APP_AUTH_SERVICE_GRPAAY_LOGIN_URL || "";
// AY AUTH SERVICE Logout
export const AY_AUTH_SERVICE_LOGOUT = window.__RUNTIME_CONFIG__
  ? window.__RUNTIME_CONFIG__.REACT_APP_AUTH_SERVICE_GRPAAY_LOGOUT_URL
  : process.env.REACT_APP_AUTH_SERVICE_GRPAAY_LOGOUT_URL || "";


  // BA WW AUTH service Login
export const BW_AUTH_SERVICE_LOGIN = window.__RUNTIME_CONFIG__
? window.__RUNTIME_CONFIG__.REACT_APP_AUTH_SERVICE_GRPBWW_LOGIN_URL
: process.env.REACT_APP_AUTH_SERVICE_GRPBWW_LOGIN_URL || "";
// BA WW AUTH SERVICE Logout
export const BW_AUTH_SERVICE_LOGOUT = window.__RUNTIME_CONFIG__
? window.__RUNTIME_CONFIG__.REACT_APP_AUTH_SERVICE_GRPBWW_LOGOUT_URL
: process.env.REACT_APP_AUTH_SERVICE_GRPBWW_LOGOUT_URL || "";

  // BA GNG AUTH service Login
  export const BA_AUTH_SERVICE_LOGIN = window.__RUNTIME_CONFIG__
  ? window.__RUNTIME_CONFIG__.REACT_APP_AUTH_SERVICE_GRPABA_LOGIN_URL
  : process.env.REACT_APP_AUTH_SERVICE_GRPABA_LOGIN_URL || "";
  // BA GNG AUTH SERVICE Logout
  export const BA_AUTH_SERVICE_LOGOUT = window.__RUNTIME_CONFIG__
  ? window.__RUNTIME_CONFIG__.REACT_APP_AUTH_SERVICE_GRPABA_LOGOUT_URL
  : process.env.REACT_APP_AUTH_SERVICE_GRPABA_LOGOUT_URL || "";
