import { configureStore } from "@reduxjs/toolkit";

import { userSlice } from "./app-slice";

export const store = configureStore({
  reducer: { app: userSlice.reducer },
});

export const {
  setToken,
  storeReset,
  setIsLoader,
  setIsSignalRConnected,
  setNotificationErrorMessage,
  setNotificationList,
  removeNotification,
  setSignalRProcessing,
  setSignalRError,
  setUserList,
  setClaimRight,
  setPerPage,
  setCurrentUser,
  setCurrentPage,
  setSelectedUserId,
  setUploadedFileUser,
  setCapacityFileInfo,
  setAccessibleRoutes,
  setSignalRConnectionDown,
  setSearchUser,
} = userSlice.actions;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
