import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  AppSliceState,
  ICapacityFileInfo,
  IClaimRightRole,
  ILoginUser,
  IRoutes,
  IUserList,
  NotificationsInterface,
} from "utils/interface";

const currentUser = {
  exp: 0,
  aud: "",
  iss: "",
  tid: "",
  Name: "",
  Email: "",
  UserId: "",
  SystemId: "",
  Username: "",
  RoleName: "",
  AirlineId: "",
  SystemName: "",
  AirlineName: "",
  AirlineCode: "",
};

const claimRight = {
  RoleId: "",
  claimRights: [],
};

const isSignalReconnectedFromStorage =
  typeof localStorage !== "undefined" &&
  localStorage.getItem("isSignalReconnected");

const parsedSignalReconnected = isSignalReconnectedFromStorage
  ? JSON.parse(isSignalReconnectedFromStorage)
  : null;

const isSignalReconnected: boolean =
  typeof parsedSignalReconnected === "boolean"
    ? parsedSignalReconnected
    : false;

const isSignalProcessingFromStorage =
  typeof localStorage !== "undefined" &&
  localStorage.getItem("isSignalRProcessing");

const parsedSignalRProcessing = isSignalProcessingFromStorage
  ? JSON.parse(isSignalProcessingFromStorage)
  : null;

const isSignalRProcessing: boolean =
  typeof parsedSignalRProcessing === "boolean"
    ? parsedSignalRProcessing
    : false;
const isDefined = typeof localStorage !== "undefined";
const initialState: AppSliceState = {
  claimRight,
  currentUser,
  userList: [],
  currentPage: 1,
  isLoader: false,
  notifications: [],
  isSignalRConnectionDown:
    (isDefined && localStorage.getItem("isSignalRConnectionDown")) || "",
  selectedUserId: "",
  accessibleRoutes: [],
  searchUser: "",
  isSignalRError: false,
  capacityFileInfo: null,
  token: (isDefined && localStorage.getItem("token")) || "",
  isSignalRProcessing: isSignalRProcessing || false,
  isSignalReconnected: isSignalReconnected || false,
  perPage: Number(isDefined && localStorage.getItem("perPageRow")) || 10,
  uploadedFileUser:
    (isDefined && localStorage.getItem("uploadedFileUser")) || "",
  notificationErrorMessage: "There are currently no notifications to display",
};

export const userSlice = createSlice({
  name: "rmt",
  initialState,
  reducers: {
    // setter method for token
    setToken: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
      localStorage.setItem("token", payload);
    },

    setSearchUser: (state, { payload }: PayloadAction<string>) => {
      state.searchUser = payload;
    },

    // setter method for user info from token decode
    setCurrentUser: (state, { payload }: PayloadAction<ILoginUser>) => {
      state.currentUser = payload;
    },

    // setter method for user info from token decode
    setIsLoader: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoader = payload;
    },

    // setter method for SignalR Connection
    setIsSignalRConnected: (state, { payload }: PayloadAction<boolean>) => {
      state.isSignalReconnected = payload;
      localStorage.setItem("isSignalReconnected", payload.toString());
    },

    // setter method for SignalR Uploaded File User
    setUploadedFileUser: (state, { payload }: PayloadAction<string>) => {
      state.uploadedFileUser = payload;
      localStorage.setItem("uploadedFileUser", payload);
    },

    // setter method for SignalR Processing
    setSignalRProcessing: (state, { payload }: PayloadAction<boolean>) => {
      state.isSignalRProcessing = payload;
      localStorage.setItem("isSignalRProcessing", payload.toString());
    },

    // setter method for user info from token decode
    setAccessibleRoutes: (state, { payload }: PayloadAction<IRoutes[]>) => {
      state.accessibleRoutes = payload;
    },

    // setter method for user info from token decode
    setUserList: (state, { payload }: PayloadAction<IUserList[]>) => {
      state.userList = payload;
    },

    setPerPage: (state, { payload }: PayloadAction<number>) => {
      state.perPage = payload;
      localStorage.setItem("perPageRow", payload.toString());
    },

    // setter method for current page in user list
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.currentPage = payload;
    },

    setNotificationErrorMessage: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.notificationErrorMessage = payload;
    },

    // setter method for Notification List
    setNotificationList: (
      state,
      { payload }: PayloadAction<NotificationsInterface[]>,
    ) => {
      if (payload.length === 1) {
        payload.forEach((notification) => {
          const existingNotification = state.notifications.find(
            (n) => n.userNotificationId === notification.userNotificationId,
          );
          if (!existingNotification) {
            state.notifications.unshift(notification);
          }
        });
      } else {
        state.notifications = payload;
      }
    },

    // setter method for remove  Notification
    removeNotification: (state, { payload }: PayloadAction<string[]>) => {
      payload.forEach((id) => {
        const notificationIndex = state.notifications.findIndex(
          (notification) => notification.userNotificationId === id,
        );
        if (notificationIndex !== -1) {
          state.notifications.splice(notificationIndex, 1);
        }
      });
    },

    // selected SignalR Error method
    setSignalRError: (state, { payload }: PayloadAction<boolean>) => {
      state.isSignalRError = state.isSignalRError ? !payload : payload;
    },

    // selected SignalR Connection down method
    setSignalRConnectionDown: (state, { payload }: PayloadAction<string>) => {
      state.isSignalRConnectionDown = payload;
      localStorage.setItem("isSignalRConnectionDown", payload.toString());
    },

    // selected user setter method
    setSelectedUserId: (state, { payload }: PayloadAction<string>) => {
      state.selectedUserId = payload;
    },

    // setter method for file info
    setCapacityFileInfo: (state, action: PayloadAction<ICapacityFileInfo>) => {
      state.capacityFileInfo = action.payload;
    },

    // login user routes claim right method
    setClaimRight: (state, action: PayloadAction<IClaimRightRole>) => {
      state.claimRight = action.payload;
    },

    // reset the redux store states
    storeReset: () => localStorage.clear(),
  },
});
