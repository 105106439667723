import {
  addSeconds,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInYears,
} from "date-fns";

export default function formatTimeFromNow(createdOn: string) {
  const now: Date = new Date();
  let momentDate: Date = new Date(createdOn);

  // Perform the equivalent of moment.add(offset, 'second')
  const offsetSeconds = new Date().getTimezoneOffset() * -60;
  momentDate = addSeconds(momentDate, offsetSeconds);

  const diffInSeconds: number = differenceInSeconds(now, momentDate);
  const diffInMinutes: number = differenceInMinutes(now, momentDate);
  const diffInHours: number = differenceInHours(now, momentDate);
  const diffInDays: number = Math.floor(differenceInDays(now, momentDate));
  const diffInYears: number = differenceInYears(now, momentDate);

  let time: string = "";

  if (diffInSeconds < 60) {
    time = `${diffInSeconds}s`;
  } else if (diffInMinutes < 60) {
    time = `${diffInMinutes}m`;
  } else if (diffInHours < 24) {
    time = `${diffInHours}h`;
  } else if (diffInDays < 7) {
    time = `${diffInDays}d`;
  } else {
    time = `${diffInYears}y`;
  }

  return time;
}
