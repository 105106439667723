import { ButtonProps } from "@mui/material";
import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

const COLORS = [
  "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "error",
] as const;

// NEW VARIANT

export default function Button(theme: Theme) {
  const rootStyle = (ownerState: ButtonProps) => {
    const containedVariant = ownerState.variant === "contained";

    const outlinedVariant = ownerState.variant === "outlined";

    const textVariant = ownerState.variant === "text";

    const smallSize = ownerState.size === "small";

    const largeSize = ownerState.size === "large";

    const defaultStyle = {
      // CONTAINED
      ...(containedVariant && {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        minWidth: "150px",

        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
      }),
      // OUTLINED
      ...(outlinedVariant && {
        borderColor: theme.palette.primary.dark,

        "&:hover": {
          borderColor: theme.palette.text.primary,
          backgroundColor: theme.palette.action.hover,
        },
      }),
      // TEXT
      ...(textVariant && {
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      }),
    };

    const colorStyle = COLORS.map((color) => ({
      ...(ownerState.color === color && {
        // CONTAINED
        ...(containedVariant && {
          "&:hover": {
            // boxShadow: theme.customShadows[color],
          },
        }),
        // SOFT
      }),
    }));

    const disabledState = {
      "&.Mui-disabled": {
        // SOFT
        pointerEvents: "unset",
        cursor: "not-allowed", // and custom cursor can be defined without :hover state
      },
    };

    const size = {
      ...(smallSize && {
        height: 30,
        fontSize: 13,
      }),
      ...(largeSize && {
        height: "100%",
        fontSize: 14,
      }),
    };

    return [...colorStyle, defaultStyle, disabledState, size];
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: ButtonProps }) =>
          rootStyle(ownerState),
      },
    },
  };
}
