import jwt from "jwt-decode";
import { memo, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import Layout from "components/Layout";
import SplashScreen from "components/splash-screen";

import {
  setAccessibleRoutes,
  setCurrentPage,
  setCurrentUser,
  setIsLoader,
  setSearchUser,
  store,
} from "store";
import { useAppSelector } from "store/hook";

import { useNotification } from "utils/hooks/useNotification";
import useSignalR from "utils/hooks/useSignalR";

import { getDashboard } from "api-services/dashboard";

import { privateRoutes, publicRoutes } from "./elements";

const AppRoute = () => {
  const { token, isLoader, currentUser, isSignalRError } = useAppSelector(
    (state) => state?.app,
  );

  const signalRMock = window.__RUNTIME_CONFIG__
    ? window.__RUNTIME_CONFIG__.REACT_APP_CYPRESS_SIGNALR_MOCK
    : process.env.REACT_APP_CYPRESS_SIGNALR_MOCK;

  const { getNotifications } = useNotification();
  const { signalRConnection } = useSignalR();

  const location = useLocation();

  useEffect(() => {
    if (token) {
      store.dispatch(setIsLoader(true));
      store.dispatch(setCurrentUser(jwt(token)));

      const handlePermissions = async () => {
        const res = await getDashboard();
        if (res) {
          store.dispatch(setAccessibleRoutes(res?.data?.dashboardItems));
          store.dispatch(setCurrentUser(jwt(res?.headers?.authorization)));
          store.dispatch(setIsLoader(false));
        }
      };
      handlePermissions();
    }
  }, [token]);

  useEffect(() => {
    if (token && signalRMock === "false") {
      getNotifications();
    }
  }, [getNotifications, token, isSignalRError, signalRMock]);

  useEffect(() => {
    if (signalRMock === "false") {
      if (token && currentUser?.Name && currentUser?.SystemId) {
        const buildSignalR = async () => {
          await signalRConnection();
        };
        buildSignalR();
      }
    }
  }, [
    signalRConnection,
    token,
    currentUser.Name,
    currentUser?.SystemId,
    signalRMock,
  ]);
  useEffect(() => {
    if (
      location.pathname !== "/users" &&
      !location.pathname.startsWith("/Users/user-details")
    ) {
      store.dispatch(setSearchUser(""));
      store.dispatch(setCurrentPage(1));
    }
  }, [location.pathname]);

  return !token ? (
    <Routes>
      {publicRoutes?.map(({ path, element }, index) => (
        <Route
          key={`private_routes_${index + 1}`}
          path={path}
          element={element}
        />
      ))}
      <Route path="*" element={<Navigate to="/qf" />} />
    </Routes>
  ) : isLoader ? (
    <SplashScreen />
  ) : (
    <Routes>
      {privateRoutes?.map(({ path, element }, index) => (
        <Route
          key={`private_routes_${index + 1}`}
          path={path}
          element={<Layout>{element}</Layout>}
        />
      ))}
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
};

export default memo(AppRoute);
