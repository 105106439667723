import axios from "utils/axios";
import { NOTIFICATION_ROUTE } from "utils/axios/endpoints";

export const getNotification = async () => {
  const response = await axios.get(NOTIFICATION_ROUTE);
  return response;
};

export const updateNotification = async (payload: string[]) => {
  const response = await axios.put(NOTIFICATION_ROUTE, {
    notificationIds: payload,
  });
  return response;
};
