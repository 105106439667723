import axios from "axios";
import jwt from "jwt-decode";

import {
  setClaimRight,
  setIsLoader,
  setNotificationErrorMessage,
  setSignalRConnectionDown,
  setSignalRProcessing,
  setUploadedFileUser,
  store,
} from "store";

import { createNotification } from "../../common/create-notification";
import {
  AA_AUTH_SERVICE_LOGOUT,
  AY_AUTH_SERVICE_LOGOUT,
  BA_AUTH_SERVICE_LOGOUT,
  BW_AUTH_SERVICE_LOGOUT,
  NOTIFICATION_ROUTE,
  QF_AUTH_SERVICE_LOGOUT,
} from "./endpoints";

let isExpired = false;

axios.defaults.baseURL = window.__RUNTIME_CONFIG__
  ? window.__RUNTIME_CONFIG__.REACT_APP_GATEWAY_API_URL
  : process.env.REACT_APP_GATEWAY_API_URL || "";

axios.interceptors.request.use(
  (req: any) => {
    const { app } = store.getState();

    req.headers.Authorization = `Bearer ${app?.token || ""}`;

    return req;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  async (res: any) => {
    if (
      ["post", "put"].includes(res?.config?.method?.toLowerCase()) &&
      res?.config?.url !== NOTIFICATION_ROUTE
    ) {
      res?.data?.messageSummary?.messages?.map(
        ({ messageIndicatorType, text }: any) =>
          createNotification({
            type: messageIndicatorType,
            message: text,
          }),
      );
    }

    const data: any = await jwt(res?.headers?.authorization);

    store.dispatch(
      setClaimRight({
        RoleId: data.RoleId,
        ClaimRights: JSON.parse(data.ClaimRights),
      }),
    );

    return res;
  },
  ({ response }: any) => {
    if (
      response?.status === 404 &&
      response?.config?.url?.includes("Customer/api/Customer/authservice")
    ) {
      createNotification({
        type: "Error",
        message: response?.data?.messageSummary?.messages[0]?.text,
      });
    }

    if (
      response?.status === 403 &&
      response.config.url === "groupCapacity/api/groupCapacity"
    ) {
      response?.data?.messageSummary?.messages?.map(
        ({ text, messageIndicatorType }: any) =>
          createNotification({
            type: messageIndicatorType,
            message: text,
          }),
      );
    }

    if (["get", "post"].includes(response?.config?.method?.toLowerCase())) {
      switch (response?.config?.url) {
        case "/notification/api/notification":
          store.dispatch(
            setNotificationErrorMessage(
              response?.data?.messageSummary?.messages[0]?.text,
            ),
          );
          store.dispatch(setUploadedFileUser(response?.data?.userName));
          break;
        case `${response?.config?.baseURL}/GroupCapacity/api/GroupCapacity`:
          store.dispatch(setSignalRProcessing(false));
          break;
        default:
          break;
      }
    }
    if (
      (response?.status === 502 ||
        response?.status === 504 ||
        response?.status === 503) &&
      response?.config?.url === "/notification/api/notification"
    ) {
      store.dispatch(setSignalRConnectionDown("down"));
      store.dispatch(
        setNotificationErrorMessage(
          "There are currently no notifications to display",
        ),
      );
    }

    if (response?.status === 401 && !isExpired) {
      const { app } = store.getState();
      isExpired = true;
      store.dispatch(setIsLoader(true));
      createNotification({
        type: "Error",
        message: response?.data?.messageSummary?.messages[0]?.text,
      });
      setTimeout(() => {
        localStorage.clear();
        localStorage.removeItem("token");
        switch (app?.currentUser?.SystemId || app?.currentUser?.tid) {
          case "GRPQAU":
            window.location.href = QF_AUTH_SERVICE_LOGOUT;
            break;
          case "GRPAAA":
            window.location.href = AA_AUTH_SERVICE_LOGOUT;
            break;
          case "GRPAAY":
            window.location.href = AY_AUTH_SERVICE_LOGOUT;
            break;
          case "GRPABA":
            window.location.href = BW_AUTH_SERVICE_LOGOUT;
            break;
          case "GROUPS":
              window.location.href = BA_AUTH_SERVICE_LOGOUT;
              break;
          default:
            break;
        }
      }, 4000);
    }
    if (
      !["get"].includes(response?.config?.method?.toLowerCase()) &&
      response?.status !== 401
    ) {
      if (
        response?.config?.url !==
        `${response?.config?.baseURL}/GroupCapacity/api/GroupCapacity`
      ) {
        response?.data?.messageSummary?.messages?.map(
          ({ text, messageIndicatorType }: any) =>
            createNotification({
              type: messageIndicatorType,
              message: text,
            }),
        );
      }
    }

    return response;
  },
);
export default axios;
