import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { useCallback, useState } from "react";

import { createNotification } from "common/create-notification";

import {
  setCapacityFileInfo,
  setIsSignalRConnected,
  setNotificationList,
  setSignalRConnectionDown,
  setSignalRError,
  setSignalRProcessing,
  setUploadedFileUser,
  store,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hook";

import { GROUP_CAPACITY_HUB } from "utils/axios/endpoints";

import { getCapacity } from "api-services/capacity";
import { getNotification } from "api-services/notification";

const useSignalR = () => {
  const { token, currentUser } = useAppSelector((state) => state?.app);
  const dispatch = useAppDispatch();

  const [signalConnection, setConnection] = useState<HubConnection | null>(
    null,
  );

  const signalRConnection = useCallback(() => {
    if (signalConnection) {
      return signalConnection;
    }
    const connection: HubConnection = new HubConnectionBuilder()
      .withUrl(
        `${
          window?.__RUNTIME_CONFIG__?.REACT_APP_NOTIFICATION_SERVICE_URL ||
          process.env.REACT_APP_NOTIFICATION_SERVICE_URL
        }${GROUP_CAPACITY_HUB}`,
        {
          accessTokenFactory: () => `Bearer ${token}`,
        },
      )
      .configureLogging(LogLevel.Information)
      .build();

    const MAX_CONNECTION_ATTEMPTS = 0;
    // Number of attempt will tell how much time will take for keep repeating to establish connection, 1 attempt will run after 30 sec

    let connectionAttempts: number = 0;

    const startConnection = async () => {
      try {
        await connection.start();
        dispatch(setSignalRConnectionDown("up"));
      } catch (error) {
        if (connectionAttempts < MAX_CONNECTION_ATTEMPTS) {
          setTimeout(() => {
            startConnection();
            connectionAttempts += 1;
          }, 15000);

          const response = await getNotification();

          if (response?.status === 200) {
            dispatch(setNotificationList(response?.data?.notifications));
            dispatch(
              setSignalRProcessing(
                !response?.data?.notifications[0]?.closeConnetion,
              ),
            );
          }
          dispatch(setSignalRConnectionDown("down"));
        } else {
          // eslint-disable-next-line no-console
          console.error("Exceeded maximum connection attempts.");
        }
      }
    };

    const handleConnectionClosed = async (error: Error | undefined) => {
      if (error) {
        startConnection();
      }
      // }
      dispatch(setSignalRConnectionDown("down"));

      const response = await getNotification();

      if (response?.status === 200) {
        dispatch(setNotificationList(response?.data?.notifications));
      }
    };

    connection.onclose((error) => {
      handleConnectionClosed(error);
    });

    const handleCapacityProcessedBySystemId = async (response: any) => {
      if (response?.user?.toLowerCase() === currentUser?.Name?.toLowerCase()) {
        dispatch(setIsSignalRConnected(true));

        dispatch(setSignalRProcessing(!response.closeConnection));
        if (response?.notificationMessagesSummary?.valid) {
          if (response.closeConnection) {
            localStorage.setItem(
              "createFileChange",
              response?.notificationMessagesSummary?.createdOn,
            );
          }
          createNotification({
            type: "Success",
            message:
              response?.notificationMessagesSummary?.notificationMessages[0]
                ?.description,
          });
          const data = {
            description:
              response?.notificationMessagesSummary?.notificationMessages[0]
                .description,
            userNotificationId:
              response?.notificationMessagesSummary?.notificationMessages[0]
                .notificationId,
            title:
              response?.notificationMessagesSummary?.notificationMessages[0]
                .title,
            status:
              response?.notificationMessagesSummary?.notificationMessages[0]
                .status,
            createdOn: response?.notificationMessagesSummary?.createdOn,
            notificationErrors: [],
          };
          dispatch(setNotificationList([data]));
          if (
            response?.notificationMessagesSummary?.notificationMessages[0]
              .messageIndicatorTypeId === "Success"
          ) {
            const res = await getCapacity();
            store.dispatch(setCapacityFileInfo(res));
          }
        } else {
          createNotification({
            type: "Error",
            message:
              response?.notificationMessagesSummary?.notificationMessages[0]
                ?.description,
          });
          dispatch(setSignalRError(true));
        }
      }

      store.dispatch(setSignalRProcessing(!response.closeConnection));
      if (response.closeConnection) {
        store.dispatch(setUploadedFileUser(""));
      } else {
        store.dispatch(setUploadedFileUser(response.user));
      }
    };

    startConnection();

    connection.on(
      `CapcityProcessedIndicator${currentUser?.SystemId}`,
      handleCapacityProcessedBySystemId,
    );

    setConnection(connection);

    return connection;
  }, [
    signalConnection,
    currentUser?.SystemId,
    currentUser?.Name,
    token,
    dispatch,
  ]);

  return { signalRConnection, signalConnection };
};

export default useSignalR;
