import { Container } from "@mui/material";
import { ReactNode, useState } from "react";

import Drawer from "components/Layout/drawer";
import NotificationDrawer from "components/Layout/drawer/notification-drawer";
import Navbar from "components/Layout/nav";

function Layout({ children }: { children: ReactNode }) {
  const [openDraw, setOpenDraw] = useState(false);
  const [openNotificationDraw, setOpenNotificationDraw] = useState(false);

  return (
    <>
      <Navbar
        setOpenDraw={setOpenDraw}
        setOpenNotificationDraw={setOpenNotificationDraw}
      />
      <Drawer openDraw={openDraw} setOpenDraw={setOpenDraw} />
      <NotificationDrawer
        openDraw={openNotificationDraw}
        setOpenDraw={setOpenNotificationDraw}
      />
      <Container sx={{ marginTop: "96px", padding: "10px 15px 30px" }}>
        {children}
      </Container>
    </>
  );
}
export default Layout;
