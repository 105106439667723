import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MUIDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Fragment } from "react";

import { ExpansionPanel } from "components/accordion";
import { BellIcon, CrossIcon } from "components/svg";

import { useAppSelector } from "store/hook";

import formatTimeFromNow from "utils/hooks/useFormatTime";
import { useNotification } from "utils/hooks/useNotification";
import { NotificationsInterface } from "utils/interface";

interface Props {
  openDraw: boolean;
  setOpenDraw: any;
}

function NotificationDrawer({ openDraw, setOpenDraw }: Props) {
  const { notifications, notificationErrorMessage } = useAppSelector(
    (state: any) => state?.app,
  );
  const theme = useTheme();

  const { updateNotifications, loader } = useNotification();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <MUIDrawer
      onClose={() => setOpenDraw(false)}
      anchor="right"
      open={openDraw}
      id="rmt_drawer_notification_wrapper"
    >
      <Box
        data-testid="rootDiv"
        sx={{
          width: isSmallScreen ? 360 : isMediumScreen ? 520 : 620,
          height: "100%",
          padding: "0 8px",
        }}
      >
        <List sx={{ height: "100%" }}>
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              background: "#ffff",
            }}
          >
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "24px 0px",
                  alignItems: "start",
                }}
              >
                <ListItemIcon
                  sx={{ minWidth: "30px", marginTop: "2px" }}
                  id="rmt_notification_drawer_bell_icon"
                >
                  <BellIcon fill="black" width="25px" height="25px" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      id="rmt_drawer_notification_title"
                    >
                      Notifications
                    </Typography>
                  }
                />
                <Box
                  component="span"
                  onClick={() => setOpenDraw(false)}
                  id="rmt_notification_drawer_close_btn"
                >
                  <CrossIcon fill="red" width="25" height="25" />
                </Box>
              </ListItemButton>
            </ListItem>
            <Divider />
            {notifications.length > 0 && (
              <>
                <ListItem
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    padding: "20px 0px",
                  }}
                  disablePadding
                >
                  <LoadingButton
                    onClick={() =>
                      updateNotifications(
                        notifications?.map(
                          (item: { userNotificationId: string }) =>
                            item.userNotificationId,
                        ),
                      )
                    }
                    type="submit"
                    variant="contained"
                    style={{
                      padding: "6px 29px",
                      color: "white",
                    }}
                    loading={loader}
                    id="rmt_drawer_notification_mark_as_read_btn"
                  >
                    Mark as all read
                  </LoadingButton>
                </ListItem>
                <Divider />
              </>
            )}
          </Box>
          {notifications.length > 0 ? (
            notifications?.map(
              (item: NotificationsInterface, index: number) => (
                <Fragment key={`notification_drawer_${index + 1}`}>
                  {item?.notificationErrors?.length > 0 ? (
                    <ExpansionPanel
                      isSmallScreen={isSmallScreen}
                      item={item}
                      index={index}
                    />
                  ) : (
                    <ListItem disablePadding>
                      <Box
                        onClick={() =>
                          updateNotifications([item.userNotificationId])
                        }
                        sx={{
                          background:
                            item.title === "File uploaded successfully"
                              ? "#90ee90"
                              : "",
                          width: isSmallScreen
                            ? 360
                            : isMediumScreen
                            ? 520
                            : 620,
                          cursor: "pointer",
                          padding: isSmallScreen ? "16px 4px" : "24px 16px",
                        }}
                        id={`rmt_drawer_notification_message_${index}`}
                      >
                        <Box
                          display="flex"
                          paddingBottom={1}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            fontSize={
                              isSmallScreen ? "0.86rem" : "1.0rem !important"
                            }
                            component="h6"
                            color="black"
                            variant="h6"
                            id={`rmt_drawer_notification_message_title_${
                              index + 1
                            }`}
                          >
                            {item.title}
                          </Typography>
                          <Box
                            whiteSpace="nowrap"
                            display="flex"
                            alignItems="center"
                          >
                            <Typography
                              variant="body2"
                              color="black"
                              id={`rmt_drawer_notification_message_time_${
                                index + 1
                              }`}
                            >
                              {formatTimeFromNow(item.createdOn)}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          paddingTop={1}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "baseline",
                              justifyContent: "space-between",
                              width: "100%",
                              marginLeft: "3px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              color="black"
                              fontSize={isSmallScreen ? "0.69rem" : "0.875rem"}
                              gutterBottom
                              id={`rmt_notification_drawer_description_time_${
                                index + 1
                              }`}
                            >
                              {item.description}
                            </Typography>
                            <Box
                              sx={{
                                width: "10px",
                                height: "10px",
                                background: "#086cb3",
                                borderRadius: 3,
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </ListItem>
                  )}

                  <Divider />
                </Fragment>
              ),
            )
          ) : (
            <Box
              padding={1}
              textAlign="center"
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <BellIcon fill="black" width="100px" height="100px" />
              <Typography
                component="p"
                id="rmt_drawer_notification_error_message"
              >
                {notificationErrorMessage}
              </Typography>
            </Box>
          )}
        </List>
      </Box>
    </MUIDrawer>
  );
}

export default NotificationDrawer;
