import { ElementType, Suspense, lazy } from "react";

import SplashScreen from "components/splash-screen";
import SuspenseLoader from "components/suspense-loader";

import { RouteInterface } from "utils/interface";

// ---------------------------- Application Loader ------------------------------------------

export const Loader = (Component: ElementType) =>
  function Loading(props: any) {
    return (
      <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
      </Suspense>
    );
  };

// ---------------------------- Wrap Components in Loader component ----------------------------------------

// login component
const LoginForm = Loader(lazy(() => import("pages/Auth/Login")));

// dashboard component
const DashboardPage = Loader(lazy(() => import("pages/Dashboard")));

// capacity component
const Capacities = Loader(lazy(() => import("pages/Capacities")));

// airline component
const AirlineDetailsPage = Loader(lazy(() => import("pages/AirlineDetails")));

// user components
const Users = Loader(lazy(() => import("pages/Users")));
const CreateUser = Loader(lazy(() => import("pages/Users/create-user")));

// ------------------------------- Application Routes Array ---------------------------------------

export const publicRoutes: RouteInterface[] = [
  {
    path: "/aa",
    element: <LoginForm />,
  },
  {
    path: "/ay",
    element: <LoginForm />,
  },
  {
    path: "/qf",
    element: <LoginForm />,
  },
  {
    path: "/bw",
    element: <LoginForm />,
  },
  {
    path: "/ba",
    element: <LoginForm />,
  },
  {
    path: "/please-wait/:id",
    element: <SplashScreen />,
  },
];

export const privateRoutes: RouteInterface[] = [
  {
    path: "/dashboard",
    element: <DashboardPage />,
  },
  {
    path: "/users",
    element: <Users />,
  },
  {
    path: "/user/create-user",
    element: <CreateUser />,
  },
  {
    path: "/airline",
    element: <AirlineDetailsPage />,
  },
  {
    path: "/capacities",
    element: <Capacities />,
  },
  {
    path: "/users/user-details/:id",
    element: <CreateUser />,
  },
  {
    path: "/user/:id",
    element: <CreateUser />,
  },
];
