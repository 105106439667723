import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { SyntheticEvent, useState } from "react";

import formatTimeFromNow from "utils/hooks/useFormatTime";
import { useNotification } from "utils/hooks/useNotification";
import { IExpansionPanel, NotificationError } from "utils/interface";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "theme/globalStyles";

export const ExpansionPanel = ({
  item,
  index,
  isSmallScreen,
}: IExpansionPanel) => {
  const { updateNotifications } = useNotification();

  const [expanded, setExpanded] = useState<string | false>("");

  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <>
      <Box
        onClick={() => updateNotifications([item.userNotificationId])}
        sx={{
          backgroundColor: "#ff7377",
          cursor: "pointer",
          paddingLeft: isSmallScreen ? "4px" : "16px",
          paddingRight: isSmallScreen ? "4px" : "16px",
          paddingTop: isSmallScreen ? "16px" : "24px",
        }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          sx={{
            fontSize: isSmallScreen ? "0.86rem" : "1.0rem",
            fontWeight: "bold",
          }}
          color="black"
          margin="0px"
        >
          {item.title}
        </Typography>
        <Box whiteSpace="nowrap" display="flex" alignItems="center">
          <Typography variant="body2" color="black">
            {formatTimeFromNow(item.createdOn)}
          </Typography>
        </Box>
      </Box>
      <Accordion
        expanded={expanded === item.userNotificationId}
        onChange={handleChange(item.userNotificationId)}
      >
        <AccordionSummary
          sx={{
            paddingTop: "4.8px",
            paddingLeft: isSmallScreen ? "4px" : "16px",
            paddingRight: isSmallScreen ? "4px" : "16px",
            paddingBottom: isSmallScreen ? "8px" : "16px",
          }}
          aria-controls="panel1d-content"
          id={`rmt_drawer_notification_error_message_${index}`}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: "0px",
            }}
            key={index}
          >
            <Box
              display="flex"
              sx={{
                paddingTop: expanded === item.userNotificationId ? "6px" : "",
              }}
              justifyContent="space-between"
            >
              <Typography
                sx={{ margin: "0px" }}
                fontSize={isSmallScreen ? "0.69rem" : "0.875rem"}
                variant="body2"
                color="black"
                gutterBottom
              >
                {item.description}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "10px",
                height: "10px",
                background: "#086cb3",
                borderRadius: 3,
              }}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              paddingLeft: "42px",
              height: item.notificationErrors.length < 20 ? "auto" : "300px",
              overflow: "auto",
            }}
            variant="body2"
            color="black"
            component="div"
          >
            <ul>
              {item.notificationErrors.map(
                (error: NotificationError, key: number) => (
                  <li
                    style={{
                      fontSize: "0.75rem",
                      paddingLeft: "6px",
                      wordBreak: "break-word",
                    }}
                    key={`error_message_${key + 1}`}
                  >
                    {error.text}
                  </li>
                ),
              )}
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
