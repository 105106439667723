import { CircularProgress, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { setToken, store } from "store";

const SplashScreen = () => {
  const { id } = useParams();

  useEffect(() => {
    // if id exist in params then update the state in redux
    if (id) {
      store.dispatch(setToken(id));
    }
  }, [id]);

  return (
    <Grid
      p={2}
      container
      flex="flex"
      width="100%"
      minHeight="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress />
        <h2>Please wait...</h2>
      </Box>
    </Grid>
  );
};
export default SplashScreen;
