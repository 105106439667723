import { toast } from "react-toastify";

import { INotification } from "utils/interface";

export const createNotification = ({ type, message }: INotification) => {
  switch (type) {
    case "Information":
      toast.success(message, {
        progress: 0,
      });
      break;
    case "Success":
      toast.success(message, {
        progress: 0,
      });
      break;
    case "Warning":
      toast.warning(message, {
        progress: 0,
      });
      break;
    case "Error":
      toast.error(message, {
        progress: 0,
      });
      break;
    default:
      return false;
  }
  return "";
};
