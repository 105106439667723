import {
  CssBaseline,
  ThemeProvider as MUIThemeProvider,
  createTheme,
} from "@mui/material";
import { StylesProvider } from "@mui/styles";
import { ReactNode } from "react";

import ComponentsOverrides from "theme/overrides";

import { GlobalStyles } from "./globalStyles";
import palette from "./palette";
import { typography } from "./typography";

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const themeOptions = {
    palette: palette(),
    typography,
  };

  const theme = createTheme(themeOptions);
  theme.components = ComponentsOverrides(theme);

  return (
    <StylesProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StylesProvider>
  );
};

export default ThemeProvider;
