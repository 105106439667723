import {
  Badge,
  Box,
  Button,
  Container,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FC } from "react";
import { Link } from "react-router-dom";

import { BellIcon, UserNavIcon } from "components/svg";

import { setIsSignalRConnected } from "store";
import { useAppDispatch, useAppSelector } from "store/hook";


type Props = {
  setOpenDraw: (value: boolean) => void;
  setOpenNotificationDraw: (value: boolean) => void;
};

const Navbar: FC<Props> = ({ setOpenDraw, setOpenNotificationDraw }) => {
  const theme = useTheme();

  const {
    currentUser,
    notifications,
    isSignalReconnected,
    isSignalRProcessing,
  } = useAppSelector((state: any) => state?.app);

  const dispatch = useAppDispatch();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      boxShadow="rgba(0, 0, 0, 0.45) 0px 0px 7px 0px;"
      bgcolor="white"
      width="100%"
      py={1}
      top={0}
      zIndex="999"
      position="fixed"
    >
      <Container sx={{ maxWidth: "1300px" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link to="/dashboard" style={{ textDecoration: "none" }}>
            <Typography
              textTransform="uppercase"
              display="flex"
              variant="h5"
              alignItems="center"
              color={theme.palette.primary.dark}
            >
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL  }/assets/logo/calrom.png`}
                alt="logo"
                maxWidth="300px"
                height="auto"
                width="75px"
                mr={2}
              />
              {currentUser.AirlineName} Revenue Management
            </Typography>
          </Link>
          <Box display={isSmallScreen ? "flex" : "block"}>
            <IconButton
              size="large"
              onClick={() => {
                dispatch(setIsSignalRConnected(false));
                setOpenNotificationDraw(true);
              }}
              id="rmt_drawer_notification_button"
            >
              <Badge badgeContent={notifications.length} color="primary">
                <BellIcon
                  isSignalReconnected={isSignalReconnected}
                  fill={isSignalReconnected ? "red" : "black"}
                  width="25px"
                  height="25px"
                />
              </Badge>
            </IconButton>
            <Button id='rmt_drawer_menu_button'
              startIcon={
                <UserNavIcon fill="black" width="25px" height="25px" />
              }
              onClick={() => setOpenDraw(true)}
            >
              {!isSmallScreen && currentUser.Name}
            </Button>
            <Box className="lineProgress">
              {isSignalRProcessing && <LinearProgress />}
            </Box>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Navbar;
