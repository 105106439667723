import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Input(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "& svg": {
              color: theme.palette.text.disabled,
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            color: "#888787",
            fontWeight: "400",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#888787",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#c2c1c1",
            borderRadius: "3px",
            color: "red",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#c2c1c1",
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: 1,
              borderColor: "#c2c1c1",
            },
          },
          "&.Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#c2c1c1",
            },
          },
        },
      },
    },
  };
}
