import { useCallback, useState } from "react";

import {
  removeNotification,
  setNotificationList,
  setSignalRProcessing,
} from "store";
import { useAppDispatch } from "store/hook";

import { getNotification, updateNotification } from "api-services/notification";

export const useNotification = () => {
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);

  const getNotifications = useCallback(async () => {
    const response = await getNotification();
    if (response?.status === 200) {
      dispatch(setNotificationList(response?.data?.notifications));
      dispatch(
        setSignalRProcessing(!response?.data?.notifications[0].closeConnetion),
      );
    }
  }, [dispatch]);

  const updateNotifications = async (id: string[]) => {
    if (id.length > 1) {
      setLoader(true);
    }

    const res = await updateNotification(id);
    if (res.status === 200) {
      dispatch(removeNotification(id));
    }
    if (id.length > 0) {
      setLoader(false);
    }
  };
  return { getNotifications, updateNotifications, loader };
};
