import axios from "utils/axios";
import {
  GET_USER_FUNCTIONALITIES_ROUTE,
  GET_USER_LIST_ROUTE,
  GET_USER_ROLE_ROUTE,
  GET_USER_SEARCH_ROUTE,
  LOGIN_ROLE_OPTIONS,
  REFERENCE_DATA_URL,
  RESET_PASSWORD,
} from "utils/axios/endpoints";
import { IPagination, ISearchUser, IUserForm } from "utils/interface";

// Get the user list
export const getUsers = async ({ page, size }: IPagination) => {
  const response = await axios.get(`${GET_USER_LIST_ROUTE}/${page}/${size}`);
  return response?.data;
};

// Get user by search string
export const handleGetSearchUser = async ({
  value,
  page,
  size,
}: ISearchUser) => {
  const response = await axios.get(
    `${GET_USER_SEARCH_ROUTE}/${value}/${page}/${size}`,
  );
  return response?.data;
};

// Add the user
export const handleSubmitUser = async (data: IUserForm) => {
  const response = await axios.post(GET_USER_LIST_ROUTE, data);
  return response;
};

// Update the user
export const handleUpdateUser = async (data: IUserForm) => {
  const response = await axios.put(GET_USER_LIST_ROUTE, data);
  return response;
};

// Disable the user
export const handleEnableDisableService = async ({ payload }: any) => {
  const response = await axios.put(
    `${GET_USER_LIST_ROUTE}/useraccess`,
    payload,
  );
  return response;
};

// Get user by ID
export const GetUserDataById = async (id: string) => {
  const response = await axios.get(`${GET_USER_LIST_ROUTE}/${id}`);
  return response?.data;
};

// Get the Roles
export const handleGetUserRole = async () => {
  const response = await axios.get(GET_USER_ROLE_ROUTE);
  return response;
};

// Get the Functionalities
export const handleGetFunctionalities = async () => {
  const response = await axios.get(GET_USER_FUNCTIONALITIES_ROUTE);
  return response?.data;
};

// handle the reset functionalities
export const handleResetPassword = async (id: string) => {
  const response = await axios.post(`${RESET_PASSWORD}/${id}`);
  return response?.data;
};

// handle the login option functionalities
export const handleGetLoginOptions = async (roleId: string) => {
  const response = await axios.get(`${LOGIN_ROLE_OPTIONS}/${roleId}`);
  return response?.data;
};

// handle the clear Cache
export const handleCache = async () => {
  const response = await axios.put(`${REFERENCE_DATA_URL}`);
  return response?.data;
};
