import { store } from "store";

import axios from "utils/axios";
import {
  GROUP_CAPACITY_ROUTE,
  UPLOAD_GROUP_CAPACITY_ROUTE,
  GROUP_CAPACITY_PURGED_ROUTE
} from "utils/axios/endpoints";
import { HandleApiRequestParams } from "utils/interface";

export const getCapacity = async () => {
  const response = await axios.get(GROUP_CAPACITY_ROUTE);
  return response?.data;
};

export const handleDownloadServerFile = async (status: any) => {
  const response = await axios.get(
    `${UPLOAD_GROUP_CAPACITY_ROUTE}/excel/${status}`,
  );
  return response;
};

export const handleApiRequest = async ({
  uploadFile,
  handleReset,
  setUploadLoading,
}: HandleApiRequestParams) => {
  const { app } = store.getState();
  const formData = new FormData();

  if (uploadFile) {
    formData.append("groupCapacityExcelFile", uploadFile);
  }

  const response = await axios.post(
    `${window.__RUNTIME_CONFIG__
      ? window.__RUNTIME_CONFIG__.REACT_APP_GATEWAY_API_URL
      : process.env.REACT_APP_GATEWAY_API_URL || ""
    }/${UPLOAD_GROUP_CAPACITY_ROUTE}`,
    formData,
    {
      headers: { Authorization: `Bearer ${app?.token}` },
    },
  );
  if (response.data?.isSucceeded) {
    handleReset();
  }
  setUploadLoading(false);
};


export const handlePurgeRuleset = async () => axios.delete(GROUP_CAPACITY_PURGED_ROUTE)

